import { Box, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { VFC } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, "auto", 3),
    maxWidth: 640,
    padding: theme.spacing(0, 2),
    textAlign: "center",
  },
  subtitle: {
    margin: theme.spacing(1, 3),
  },
}));

export type OnboardingTitleProps = {
  title: string;
  subtitle?: string;
};

export const OnboardingTitle: VFC<OnboardingTitleProps> = ({ title, subtitle }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={classes.root}>
      <Typography component="h1" variant="h3">
        {title}
      </Typography>
      {!!subtitle && (
        <Typography variant="subtitle1" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};
