import { Box, IconButton, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import React, { useMemo, VFC } from "react";
import { useUserContext } from "../../context/UserContext";
import LogoOnBlackSvg from "../../img/logo-onboarding.svg";
// eslint-disable-next-line no-restricted-imports
import { UserOnboard } from "../../reclaim-api/client";
import { DoneStepBuffet } from "./DoneStepBuffet";
import { OnboardingTitle } from "./OnboardingTitle";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
  },
  logo: {
    display: "block",
    margin: theme.spacing(2, "auto", 3),
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(5, "auto"),
    },
    "&&:hover": {
      background: "transparent",
    },
  },
  logoBtn: {
    cursor: "default",
    "&:hover": {
      background: "transparent",
    },
  },
  logoSvg: {
    height: "auto",
    maxWidth: 120,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(-2),
      maxWidth: 220,
    },
  },
  closeIcon: {
    position: "absolute",
    zIndex: theme.zIndex.modal + 1,
    right: theme.spacing(2),
    top: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      right: theme.spacing(3),
      top: theme.spacing(3),
    },
  },
}));

export type OnboardingBuffetProps = {
  onClose?: () => void; // If oboarding is dialog child
};

export const OnboardingBuffet: VFC<OnboardingBuffetProps> = ({ onClose }) => {
  const classes = useStyles();

  const [{ user }] = useUserContext();

  const onboardings = useMemo<UserOnboard | undefined>(() => user?.features.onboard, [user]);

  return (
    <Box className={classes.root}>
      {!!onClose && (
        <IconButton onClick={onClose} className={classes.closeIcon}>
          <CloseRoundedIcon />
        </IconButton>
      )}

      <Box className={classes.logo}>
        <IconButton className={classes.logoBtn} size="medium" aria-label="reclaim.ai" disableRipple disableFocusRipple>
          <LogoOnBlackSvg className={classes.logoSvg} preserveAspectRatio="xMidYMid meet" />
        </IconButton>
      </Box>

      <OnboardingTitle
        title="Get started with Reclaim"
        subtitle="Set up our full suite of tools to make your work-life more efficient"
      />
      <DoneStepBuffet onboardings={onboardings} />
    </Box>
  );
};
