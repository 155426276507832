import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React, { createContext, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      height: "100vh",
      overflow: "auto",
      padding: theme.spacing(2),
    },
  },
  openSection: {
    flex: 1,
    alignItems: "flex-start",
  },
  unusedSection: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    paddingLeft: 2,
  },
  endOfUnusedAdornment: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0 0 0 auto",
  },
  inlineIcon: {
    marginRight: theme.spacing(2),
    opacity: 0.6,
  },
}));

export const MicroFormContext = createContext<{
  pushPage: (name: string) => void;
  overideSectionOpen: (name: string, open: boolean) => void;
  pageIndex: number;
  overideOpenMap: { [key: string]: boolean };
  setNeedsToBeFocused: (name: string, open: boolean) => void;
  needsFocusMap: { [key: string]: boolean };
  pagesAreaRef: any;
  pageName?: string;
}>({
  pushPage: () => null,
  overideSectionOpen: () => null,
  pageIndex: 0,
  overideOpenMap: {},
  setNeedsToBeFocused: () => null,
  needsFocusMap: {},
  pagesAreaRef: {},
});

export type MicroFormLayoutProps = {
  endOfUnusedAdornment?: React.ReactElement;
  children?: React.ReactNode;
};

export const MicroFormLayout = forwardRef<any, MicroFormLayoutProps>(({ children, endOfUnusedAdornment }, ref) => {
  const classes = useStyles();
  const [pages, setPages] = useState<Array<string>>([]);
  const [overideOpenMap, setOverideOpenMap] = useLocalStorage<{ [key: string]: boolean }>("micro.task.section", {});

  const [needsFocusMap, setNeedsFocusMap] = useState<{ [key: string]: boolean }>({});
  const pagesAreaRef = useRef<any>({});
  const [, setRerender] = useState(0);

  useImperativeHandle(ref, () => ({
    reset: () => {
      setOverideOpenMap({});
      setRerender(0);
      setPages([]);
      setNeedsFocusMap({});
    },
  }));

  return (
    <MicroFormContext.Provider
      value={{
        pushPage: (page: string) => {
          setPages(pages.concat([page]));
          // trigger re-render to load page refs into context
          setTimeout(() => {
            setRerender(Math.random());
          }, 20);
        },
        setNeedsToBeFocused: (id: string, needs: boolean) => {
          setNeedsFocusMap({ ...needsFocusMap, [id]: needs });
        },
        needsFocusMap,
        overideSectionOpen: (name: string, open: boolean) => {
          setOverideOpenMap({ ...overideOpenMap, [name]: open });
        },
        overideOpenMap,
        pageIndex: pages.length - 1,
        pageName: pages[pages.length - 1],
        pagesAreaRef: pagesAreaRef.current,
      }}
    >
      <Box className={classes.main}>
        {/* Display MicroFormSection components here that are open OR non MicroFormSection components*/}
        <Box className={classes.openSection}>
          {React.Children.map(children, (child: any) => {
            if (
              (child && !Object.prototype.hasOwnProperty.call(child, "props")) ||
              child?.props?.__TYPE !== "MicroFormSection" ||
              (child?.props?.__TYPE === "MicroFormSection" && (!!child.props.open || !!overideOpenMap[child.props.id]))
            ) {
              return <React.Fragment key={child?.props?.id}>{child}</React.Fragment>;
            }

            return null;
          })}
        </Box>

        {/* Only display MicroFormSection components here that are closed */}
        <Box className={classes.unusedSection}>
          {React.Children.map(children, (child: any) => {
            if (
              child &&
              !!Object.prototype.hasOwnProperty.call(child, "props") &&
              child?.props?.__TYPE === "MicroFormSection" &&
              !child.props.open &&
              !overideOpenMap[child.props.id]
            ) {
              return (
                <span className={classes.inlineIcon}>
                  <React.Fragment key={child?.props?.id}>{child}</React.Fragment>
                </span>
              );
            }
            return null;
          })}

          <Box className={classes.endOfUnusedAdornment}>{endOfUnusedAdornment}</Box>
        </Box>
      </Box>
    </MicroFormContext.Provider>
  );
});
