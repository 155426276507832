import { Box, IconButton, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import React, { useCallback, VFC } from "react";
import { Accounts } from ".";
import { useAnalyticsContext } from "../../context/AnalyticsContext";
import { useOurRouter } from "../../hooks/useOurRouter";
import { usePromise } from "../../hooks/usePromise";
import LogoOnBlackSvg from "../../img/logo-onboarding.svg";
import { reclaim } from "../../reclaim-api";
import { ConnectedAccount } from "../../reclaim-api/Accounts";
import { Loading } from "../Loading";
import { OnboardingTitle } from "../onboarding/OnboardingTitle";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
  },
  logo: {
    display: "block",
    margin: theme.spacing(2, "auto", 3),
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(5, "auto"),
    },
    "&&:hover": {
      background: "transparent",
    },
  },
  logoBtn: {
    cursor: "default",
    "&:hover": {
      background: "transparent",
    },
  },
  logoSvg: {
    height: "auto",
    maxWidth: 120,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(-2),
      maxWidth: 220,
    },
  },
  closeIcon: {
    position: "absolute",
    zIndex: theme.zIndex.modal + 1,
    right: theme.spacing(2),
    top: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      right: theme.spacing(3),
      top: theme.spacing(3),
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    maxWidth: 500,
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },
}));

export type AccountRecoveryProps = {
  onClose?: () => void; // If oboarding is dialog child
};

export const AccountRecovery: VFC<AccountRecoveryProps> = ({ onClose }) => {
  const classes = useStyles();

  const router = useOurRouter();

  const {
    state: { segment },
  } = useAnalyticsContext();

  const {
    data: accounts,
    loading: accountsLoading,
    load: loadAccounts,
  } = usePromise<ConnectedAccount[]>(reclaim.accounts.list, []);

  const handleReconnect = useCallback(
    async (e, account) => {
      segment?.track("Reconnect Account Clicked", {
        category: "Account Recovery",
        account: account.id,
      });

      return reclaim.accounts.authRedirect(account.repairURI, { redirect: router.asPath, accountId: account.id });
    },
    [router.asPath, segment]
  );

  const handleUseAsMain = useCallback(
    async (e, account) => {
      segment?.track("Switch Main Account Clicked", {
        category: "Account Recovery",
        account: account.id,
      });

      return reclaim.accounts.authRedirect(account.switchToMainURI, {
        redirect: router.asPath,
        accountId: account.id,
      });
    },
    [router.asPath, segment]
  );

  const handleDelete = useCallback(
    async (e, account: ConnectedAccount) => {
      segment?.track("Delete Account Clicked", {
        category: "Account Recovery",
        account: account.id,
      });

      await reclaim.accounts.delete(account.id);
      await loadAccounts();
    },
    [loadAccounts, segment]
  );

  return (
    <Box className={classes.root}>
      {!!onClose && (
        <IconButton onClick={onClose} className={classes.closeIcon}>
          <CloseRoundedIcon />
        </IconButton>
      )}

      <Box className={classes.logo}>
        <IconButton className={classes.logoBtn} size="medium" aria-label="reclaim.ai" disableRipple disableFocusRipple>
          <LogoOnBlackSvg className={classes.logoSvg} preserveAspectRatio="xMidYMid meet" />
        </IconButton>
      </Box>

      <OnboardingTitle
        title="Invalid main account"
        subtitle="Reclaim is unable to access your main calendar. Please reconnect the account or select a different main account."
      />

      {!!accountsLoading && <Loading />}
      {!accountsLoading && !!accounts && (
        <Box className={classes.body}>
          <Accounts
            accounts={accounts}
            onReconnect={handleReconnect}
            onDelete={handleDelete}
            onUseAsMain={handleUseAsMain}
          />
        </Box>
      )}
    </Box>
  );
};
