import { useEffect, useState } from "react";
import { pipe, Source, subscribe } from "wonka";
import { hash } from "../utils/objects";

/**
 * Generate an observable from source arguments and subscribe, with the result
 * being the most recently emitted value.
 * 
 * @param source function that generates the source observable
 * @param sourceArguments args provided to the source function
 * @param enabled when true, source is called with sourceArguments and subscribed to
 * @returns current value of the generated observable
 */
export const useSubscription = <T, A extends unknown[]>(
  source: (...args: A) => Source<T>,
  sourceArguments: A = [] as unknown as A,
  enabled: boolean = true
): T | undefined => {
  const [currentValue, setCurrentValue] = useState<T | undefined>();
  const [currentArguments, setCurrentArguments] = useState<A>(sourceArguments);

  /**
   * Only update arguments if they've actually changed. This prevents
   * accidental infinite loops when source args aren't properly memoized
   */
  useEffect(() => {
    setCurrentArguments((prev) => {
      return hash(prev) === hash(sourceArguments) ? prev : sourceArguments;
    });
  }, [sourceArguments]);

  /**
   * Call source function and subscribe, resubscribe any time source arguments change.
   */
  useEffect(() => {
    if (!enabled) return;
    let ignore = false;

    // On mount, subscribe to the operator using the subject and schedule a teardown using scheduler (1)
    // Start the subscription using the subject and operator
    const { unsubscribe } = pipe(
      source(...currentArguments),
      subscribe((value) => {
        if (!ignore) setCurrentValue((prev) => (value !== prev ? value : prev));
      })
    );

    return () => {
      ignore = true;
      if (!!unsubscribe) unsubscribe();
    };
  }, [currentArguments, enabled, source]);

  return currentValue;
};
