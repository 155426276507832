import { Chip, darken, lighten, makeStyles, Theme, useTheme } from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import React, { useMemo } from "react";
import { useSubscription } from "../hooks/useSubscription";
import { NotificationSyncStatus, TransformDomain } from "../reclaim-api/types";

const useStyles = makeStyles((theme: Theme) => ({
  statusMessage: {
    marginRight: theme.spacing(2),
  },
  spin: {
    color: theme.palette.success.main,
    width: 20,
    height: 20,
    animationName: "spin",
    animationDuration: "3000ms",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
  },
}));

export const SyncStatus: React.VFC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const syncStatus = useSubscription(() => TransformDomain.syncStatus$);

  const label = useMemo(() => {
    switch (syncStatus) {
      case NotificationSyncStatus.SyncingServer:
        return "Syncing Reclaim";
      case NotificationSyncStatus.SyncingCalendar:
        return "Syncing Calendar";
      case NotificationSyncStatus.Ready:
      default:
        return "Done syncing";
    }
  }, [syncStatus]);

  return (
    <>
      {!!syncStatus && syncStatus !== NotificationSyncStatus.Ready && (
        <Chip
          className={classes.statusMessage}
          icon={
            syncStatus === NotificationSyncStatus.SyncingServer ? (
              <SyncIcon
                className={classes.spin}
                style={{
                  color: darken(theme.palette.primary.light, 0.3),
                }}
              />
            ) : (
              <SyncIcon
                className={classes.spin}
                style={{
                  color: darken(theme.palette.success.dark, 0.3),
                }}
              />
            )
          }
          style={{
            backgroundColor: lighten(
              syncStatus === NotificationSyncStatus.SyncingServer
                ? theme.palette.primary.light
                : theme.palette.success.light,
              0.8
            ),
            color: darken(
              syncStatus === NotificationSyncStatus.SyncingServer
                ? theme.palette.primary.light
                : theme.palette.success.dark,
              0.3
            ),
          }}
          label={label}
        />
      )}
    </>
  );
};
