import Button, { ButtonProps } from "@material-ui/core/Button";
import { DialogProps } from "@material-ui/core/Dialog";
import React, { useState } from "react";
import { ConfirmDialog } from "./ConfirmDialog";

export type ConfirmButtonProps<C extends React.ReactElement> = Omit<ButtonProps, "onClick"> & {
  title?: string;
  message?: string | React.ReactElement;
  confirmLabel?: string;
  declineLabel?: string;
  component?: C;
  DialogProps?: Omit<DialogProps, "open" | "onClose" | "aria-labelledby" | "aria-describedby">;
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>) => void;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>) => Promise<void> | void;
  onCancel?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>) => void;
  onClose?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>) => void;
  onSuccess?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>, value: unknown) => void;
  onError?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>, error: Error) => void;
};

export const ConfirmButton: React.FC<ConfirmButtonProps<React.ReactElement>> = ({
  title = "Confirm",
  message = "",
  confirmLabel = "Confirm",
  declineLabel = "Cancel",
  component = <Button />,
  onClick,
  onConfirm,
  onCancel,
  onClose,
  onSuccess,
  onError,
  DialogProps,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(false);

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (onClick) onClick(e);
    setOpen(true);
  }

  const handleClose = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>) => {
    if (!!onClose) onClose(e);
    setOpen(false);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>) => {
    if (!!onCancel) onCancel(e);
    setOpen(false);
  };

  const handleSuccess = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>,
    value: unknown
  ) => {
    if (!!onSuccess) onSuccess(e, value);
    setOpen(false);
  };

  return (
    <>
      {React.cloneElement(component, { ...rest, onClick: handleClick })}
      <ConfirmDialog
        title={title}
        message={message}
        open={open}
        DialogProps={DialogProps}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={onConfirm}
        onSuccess={handleSuccess}
      ></ConfirmDialog>
    </>
  );
};
