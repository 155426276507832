import { makeStyles, Portal, Tooltip, TooltipProps } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useShortcut } from "../../hooks/useShortcut";
import { MicroFormContext } from "./MicroFormLayout";

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  iconWithText: {
    whiteSpace: "nowrap",
    display: "flex",
  },
  openIcon: {
    flex: 0,
    marginRight: theme.spacing(2),
    minWidth: 24,
    paddingTop: theme.spacing(0.25),
  },
  openDetails: {
    flex: 1,
  },
  iconNotClickable: {
    color: theme.colors.primary,
    display: "flex",
    fontSize: 16,
    justifyContent: "center",
  },
  iconClickable: {
    color: theme.colors.primary,
    display: "flex",
    fontSize: 16,
    height: 24,
    justifyContent: "center",
    width: 24,
  },
  iconClosed: {
    color: theme.colors.primary,
    display: "flex",
    padding: 0,
  },
}));

const IconWrap = ({ children, iconMenuOpen, ...rest }: TooltipProps & { iconMenuOpen?: boolean }) =>
  iconMenuOpen ? (
    <span>{children}</span>
  ) : (
    <Tooltip {...rest} placement="left">
      {children}
    </Tooltip>
  );

export type MicroFormSectionProps = {
  title: string;
  id: string;
  iconActionTitle?: string;
  icon: any;
  canTabIcon?: boolean;
  open: boolean;
  isToggle?: true;
  onUserInputValue?: (v: string) => void;
  onToggleValue?: (v: boolean) => void;
  renderAsNavPage?: (clickTarget: React.MutableRefObject<any>, onClose: () => void) => any;
  onIconClick?: (clickTarget: React.MutableRefObject<any>) => any;
  children: any;
  __TYPE?: string; // FIXME (IW) ?????
};

export const MicroFormSection: React.FC<MicroFormSectionProps> = ({
  title,
  id,
  icon,
  open,
  onToggleValue,
  isToggle,
  canTabIcon,
  children,
  renderAsNavPage,
  onIconClick,
  iconActionTitle,
}) => {
  const classes = useStyles();

  const formLayoutContext = useContext(MicroFormContext);

  const [focusedIcon, setFocusedIcon] = useState(false);
  const [renderPage, setRenderPage] = useState(false);

  const iconMenuOpen = useMemo(() => formLayoutContext.overideOpenMap[id], [formLayoutContext.overideOpenMap, id]);

  const iconButtonRef = useRef<any>();
  const openIconButtonRef = useRef<any>();
  const contentRef = useRef<HTMLDivElement>();

  useShortcut(
    "enter",
    (event: KeyboardEvent) => {
      if (focusedIcon && !open && !!isToggle) {
        formLayoutContext.overideSectionOpen(id, true);
        if (onToggleValue) onToggleValue(open);
        formLayoutContext.setNeedsToBeFocused(id, true);
      }
    },
    [focusedIcon, open]
  );

  useEffect(() => {
    if (formLayoutContext.pageIndex === 0 && iconMenuOpen) {
      formLayoutContext.overideSectionOpen(id, false);
    }
  }, [formLayoutContext, formLayoutContext.pageIndex, iconMenuOpen, id]);

  useEffect(() => {
    if (!!formLayoutContext.needsFocusMap[id] && !!contentRef.current && formLayoutContext.overideOpenMap[id]) {
      const firstTabIndex = contentRef.current?.querySelectorAll(
        '.MuiIconButton-root, .MuiInput-input, a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
      )[0] as any;
      formLayoutContext.setNeedsToBeFocused(id, false);

      if (!firstTabIndex && openIconButtonRef.current && isToggle && canTabIcon) {
        return (openIconButtonRef.current.querySelectorAll(".MuiIconButton-root, a, button")[0] as any).focus();
      }

      firstTabIndex?.focus();
    }
  }, [canTabIcon, formLayoutContext, id, isToggle]);

  useEffect(() => {
    if (formLayoutContext.pageName !== id && renderPage) {
      setRenderPage(false);
    }
  }, [openIconButtonRef, formLayoutContext.pageName, id, renderPage]);

  if (!open && !iconMenuOpen)
    return (
      <span className={classes.iconWithText} ref={iconButtonRef}>
        <IconWrap iconMenuOpen={iconMenuOpen} title={title}>
          <IconButton
            className={classes.iconClosed}
            onClick={(e) => {
              formLayoutContext.overideSectionOpen(id, true);
              if (!!isToggle && !!onIconClick) onIconClick(iconButtonRef);

              formLayoutContext.setNeedsToBeFocused(id, true);
            }}
            onFocus={() => setFocusedIcon(true)}
            onBlur={() => setFocusedIcon(false)}
            size="small"
          >
            {icon}
          </IconButton>
        </IconWrap>
      </span>
    );

  return (
    <Box className={classes.section}>
      <div className={classes.openIcon} ref={openIconButtonRef}>
        {(!!onIconClick || !!renderAsNavPage) && (
          <IconWrap key={`${id}-icon-wrap`} title={iconActionTitle || `${title} icon - No action to perform`}>
            <IconButton
              key={`${id}-icon`}
              tabIndex={canTabIcon ? undefined : -1}
              className={classes.iconClickable}
              size="small"
              onClick={(e) => {
                if (!!onIconClick) return onIconClick(openIconButtonRef);
                if (!!renderAsNavPage) {
                  setRenderPage(true);
                  return setTimeout(() => {
                    // This timeout allows the tooltip to be removed
                    formLayoutContext.pushPage(id);
                  }, 2);
                }
                return;
              }}
            >
              {icon}
            </IconButton>
          </IconWrap>
        )}
        {!onIconClick && !renderAsNavPage && <span className={classes.iconNotClickable}>{icon}</span>}
      </div>
      <div className={classes.openDetails} ref={contentRef as any}>
        {children}
      </div>

      <Portal container={formLayoutContext.pagesAreaRef[id]}>
        {renderPage && renderAsNavPage?.(iconButtonRef, () => formLayoutContext.overideSectionOpen(id, false))}
      </Portal>
    </Box>
  );
};

// FIXME (IW): WTF is this?!

// if this is not here in real un-commented code, re-add it
// MicroFormSection.defaultProps = {
//   __TYPE: "MicroFormSection",
// };

MicroFormSection.defaultProps = {
  __TYPE: "MicroFormSection",
};
