/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactElement, useCallback, useMemo } from "react";
import { FieldValues, useController, UseControllerProps, UseControllerReturn } from "react-hook-form";
import { IRhfControl, RhfControlProps, RhfControlRenderProps } from ".";

export type RhfTransform = {
  input?: (val: any) => any;
  output?: (...e: any) => any;
};

export type RhfControlledRenderProps = RhfControlRenderProps & UseControllerReturn;

export type RhfControlledProps<
  FV extends FieldValues = FieldValues,
  RP extends RhfControlledRenderProps = RhfControlledRenderProps
> = RhfControlProps &
  UseControllerProps & {
    transform?: RhfTransform;
    control?: UseControllerProps<FV>;
    render: (props: RP) => ReactElement;
    hasChildControl?: boolean;
  };

export const RhfControlled: IRhfControl<RhfControlledProps> = ({
  name,
  control,
  rules,
  transform,
  defaultValue,
  render,
  ...rest
}) => {
  const { field, fieldState, formState } = useController({
    control,
    name,
    defaultValue,
    rules,
  });

  /**
   * Transform input if `transform.input` prop provided
   */
  const value = useMemo(
    () => (!!transform?.input ? transform.input(field.value) : field.value),
    [field.value, transform]
  );

  /**
   * Transform output if `transform.output` prop provided
   */
  const onChange = useCallback(
    (...e: any[]) => (!!transform?.output ? field.onChange(transform.output(...e)) : field.onChange(...e)),
    [field, transform]
  );

  return render({
    ...{
      ...rest,
      name,
      field: { ...field, onChange, value },
      fieldState,
      formState,
    },
  });
};

RhfControlled.isControl = true;
RhfControlled.isController = true;
