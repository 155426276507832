import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import React, { FC, MouseEvent, useCallback, useMemo } from "react";
import { useUserContext } from "../../context/UserContext";
import { reclaim } from "../../reclaim-api";
import { ConnectedAccount } from "../../reclaim-api/Accounts";
import { AuthMode } from "../../reclaim-api/Credentials";
import { AccountErrors } from "./AccountErrors";
import { AccountListItem } from "./AccountListItem";

const useStyles = makeStyles((theme: Theme) => ({
  accountList: {
    flex: 1,
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
  main: {
    marginBottom: theme.spacing(2),
  },
  addAccountBtn: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(1, 0, 2),
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  addAccountIcon: {
    height: 24,
    marginRight: theme.spacing(2),
    width: 24,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(3.5),
      marginLeft: theme.spacing(1.5),
    },
  },
  subtext: {
    marginBottom: theme.spacing(3),
    maxWidth: 600,
  },
  modalList: {
    listStyleType: "disc",
    margin: theme.spacing(1, 0, 2),
    maxWidth: 600,
    paddingLeft: theme.spacing(3),
    paddingTop: 0,
    "& li": {
      display: "list-item",
      padding: theme.spacing(0.5, 0),
    },
  },
  modalAlert: {
    marginBottom: theme.spacing(3),
    maxWidth: 600,
  },
  switchBtn: {
    marginBottom: theme.spacing(3),
  },
  switchDialog: {
    paddingTop: theme.spacing(5),
    position: "relative",
    "&&-root:first-child": {
      padding: theme.spacing(5, 4, 3),
    },
  },
  switchTitle: {
    marginTop: theme.spacing(3),
  },
  closeModal: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  enhance: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export type AccountsProps = {
  accounts: ConnectedAccount[];
  onReconnect?: (e: MouseEvent<HTMLButtonElement>, account: ConnectedAccount) => Promise<void>;
  onUseAsMain?: (e: MouseEvent<HTMLButtonElement>, account: ConnectedAccount) => Promise<void>;
  onDelete?: (e: MouseEvent<HTMLButtonElement>, account: ConnectedAccount) => Promise<void>;
};

export const Accounts: FC<AccountsProps> = ({ accounts, onReconnect, onUseAsMain, onDelete }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  const [{ user }] = useUserContext();

  const mainAccount = useMemo(() => accounts?.find((a) => !!a.main), [accounts]);

  const [activeDialogAccount, setActiveDialogAccount] = React.useState<ConnectedAccount | null>(null);

  const handleUseAsMain = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (!activeDialogAccount || !!activeDialogAccount.main) return;
      onUseAsMain?.(e, activeDialogAccount);
    },
    [activeDialogAccount, onUseAsMain]
  );

  const handleOpen = useCallback(async (e, account: ConnectedAccount) => {
    setActiveDialogAccount(account);
  }, []);

  const handleClose = () => {
    setActiveDialogAccount(null);
  };

  const renderAccountSwitchDialogContent = useCallback(() => {
    return (
      activeDialogAccount !== null && (
        <DialogContent className={classes.switchDialog}>
          <IconButton className={classes.closeModal} onClick={handleClose}>
            <CloseRoundedIcon color="primary" />
          </IconButton>
          <Typography className={classes.switchTitle} variant="h4">
            Switch Main Account
          </Typography>
          <Typography className={classes.subtext}>
            Ready to switch your main account from{" "}
            <span className={classes.enhance}>{accounts?.find((e) => e.main === true)?.name}</span> to{" "}
            <span className={classes.enhance}>{activeDialogAccount.name}</span>?
          </Typography>
          <Typography variant="h6">What this means:</Typography>
          <List className={classes.modalList}>
            <ListItem>
              <Typography>
                Reclaim will stop scheduling events on{" "}
                <span className={classes.enhance}>{accounts?.find((e) => e.main === true)?.name}</span> and start
                scheduling on <span className={classes.enhance}>{activeDialogAccount.name}</span>.
              </Typography>
            </ListItem>
            {!!user?.features?.smartOneOnOnes?.enabled && (
              <ListItem>
                <Typography>
                  Any Smart 1:1s you've created with{" "}
                  <span className={classes.enhance}>{accounts?.find((e) => e.main === true)?.name}</span> will be
                  disabled. Any Smart 1:1s your{" "}
                  <span className={classes.enhance}>{accounts?.find((e) => e.main === true)?.name}</span> email has been
                  invited to will be declined.
                </Typography>
              </ListItem>
            )}
            <ListItem>
              <Typography>Syncs already set up will be unaffected.</Typography>
            </ListItem>
          </List>
          <Alert className={classes.modalAlert} severity="warning">
            Switching your main account can take up to 1h. Please don't delete or revoke access to{" "}
            <span className={classes.enhance}>{activeDialogAccount.name}</span> in the meantime.
          </Alert>
          <Button className={classes.switchBtn} variant="contained" color="primary" onClick={handleUseAsMain}>
            Switch Main Account
          </Button>
        </DialogContent>
      )
    );
  }, [
    accounts,
    activeDialogAccount,
    classes.closeModal,
    classes.enhance,
    classes.modalAlert,
    classes.modalList,
    classes.subtext,
    classes.switchBtn,
    classes.switchDialog,
    classes.switchTitle,
    handleUseAsMain,
    user?.features?.smartOneOnOnes?.enabled,
  ]);

  return (
    <>
      {/* errors */}
      <AccountErrors />

      {/* account list */}
      {!!mainAccount && (
        <>
          <List className={clsx(classes.accountList, classes.main)}>
            <AccountListItem
              key={mainAccount.id}
              onReconnect={onReconnect}
              onUseAsMain={handleOpen}
              onDelete={onDelete}
              account={mainAccount}
            />
          </List>
          <Divider orientation="horizontal" />
        </>
      )}
      <List className={classes.accountList}>
        {accounts
          ?.filter((a) => !a.main)
          .map((account) => (
            <AccountListItem
              key={account.id}
              onReconnect={onReconnect}
              onUseAsMain={handleOpen}
              onDelete={onDelete}
              account={account}
            />
          ))}

        {/* add account */}
        <ListItem
          disableGutters
          className={classes.addAccountBtn}
          button
          onClick={() => {
            reclaim.credentials.save(AuthMode.Secondary);
          }}
        >
          <PersonAddOutlinedIcon className={classes.addAccountIcon} fontSize="medium" />
          Add another account
        </ListItem>
      </List>

      {/* account switch dialog */}
      <Dialog open={activeDialogAccount !== null} onClose={handleClose} fullScreen={small}>
        {renderAccountSwitchDialogContent()}
      </Dialog>
    </>
  );
};
